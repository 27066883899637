<template>
  <div
    style="
      background-color: #171726;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
    "
  >
    <back-nav-last-page
      class="title_top"
      :icon="'img/backw.png'"
      :textColor="'#ffffff'"
      :backColor="'none'"
    />
    <div class="input_content">
      <div class="input_box">
        <input
          v-model="walletInfo.phone"
          disabled
          class="input_item"
          type="text"
          placeholder=""
        />
        <div id="money" class="money" @click="getSMSCode" ref="popups">
          {{ countStart ? count + "秒后可重新获取" : "获取验证码" }}
        </div>
      </div>
      <div class="input_box_">
        <input
          v-model="sms_verify"
          class="input_item"
          type="number"
          placeholder="请输入验证码"
        />
      </div>
      <div class="input_box_">
        <input
          v-model="alipay_card"
          class="input_item"
          type="text"
          placeholder="请输入支付宝账户"
        />
      </div>
      <div class="btn_box_">
        <div class="btn_item" @click="bindAlipay">提交</div>
      </div>
      <p>1.一周内仅可修改一次；</p>
      <p>2.仅可修改同属收款人姓名下的支付宝账号；</p>
      <p>3.为了保证用户财产安全，官方不会接收其他非正式理由为用户修改收款账户信息；</p>
      <p>4.如有任何疑问请联系官方客服。</p>
      <div id="captcha"></div>
    </div>
  </div>
</template>
  <script>
import BackNavLastPage from "@/components/backNavLastPage.vue";
import { getSmsCode, bindAlipay, walletIndex } from "@/api/wallet";
export default {
  name: "bindAlipay",
  components: {
    BackNavLastPage,
  },
  data() {
    return {
      countStart: false,
      walletInfo:{},
      count: 60,
      sms_verify: '',
      alipay_card:'',
      captchaIns: null,
      timer: null,
    };
  },
  created() {
    // console.log(this.initNECaptcha);
    this.getWalletIndex()
  },
  mounted() {
    // 若使用降级方案接入
    // initNECaptcha 替换成 initNECaptchaWithFallback
    let that = this;
    initNECaptcha(
      {
        captchaId: "848c089919be41cda0aed1ca4dad4620",
        element: "#captcha",
        mode: "popup",
        width: "320px",
        apiVersion: 2,

        onVerify: (err, data) => {
          // 当验证失败时, 内部会自动 refresh 方法, 无需手动再调用一次
          if (err) return;
          // 以下为业务侧逻辑
          console.log(data.validate);
          that.doSmsSend(data.validate);
        },
      },
      function onload(instance) {
        that.captchaIns = instance;
      },
      function onerror(err) {
        // 初始化失败后触发该函数, err 对象描述当前错误信息
        console.log(err);
      }
    );
  },
  methods: {
    getWalletIndex() {
      walletIndex({}).then((res) => {
        console.log(res);
        this.walletInfo = res.data;

      });
    },
    bindAlipay() {
      if (this.alipay_card == "") {
        this.$toast.fail("请输入支付宝账号");
        return false;
      }
      if (this.sms_verify == "" || this.sms_verify.length != 4) {
        this.$toast.fail("请输入4位数验证码");
        return false;
      }
      let params = {
        alipay_card:this.alipay_card,
        sms_verify: this.sms_verify,
      };
      bindAlipay(params).then(
        (res) => {
          if (res.code == 10000) {
            this.$toast.success("修改成功");
          }
        },
        (error) => {
          console.log(error);
          this.$toast.fail(error);
        }
      );
    },
    getSMSCode() {
      if (this.countStart) {
        return false;
      }
      this.captchaIns && this.captchaIns.verify();
    },
    doSmsSend(data) {
      let params = {
        captcha: data,
        phone: this.walletInfo.real_phone,
        sms_type: 4,
      };
      getSmsCode(params).then((res) => {
        if (res.code == 10000) {
          this.countStart = true;
          this.receiveCode();
        }
      });
    },
    receiveCode() {
      this.timer = setInterval(() => {
        this.count--;
        if (this.count <= 0) {
          this.countStart = false;
          this.count = 60;
          this.captchaIns.refresh();
          clearInterval(this.timer);
        }
      }, 1000);
    },
  },
};
</script>
  <style scoped>
.input_content {
  padding: 0 40px 0 40px;
}

.input_box {
  padding: 0 40px 0 40px;
  display: flex;
  align-items: center;
  height: 80px;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.04);
  margin-top: 60px;
}

.input_box_ {
  padding: 0 40px 0 40px;
  display: flex;
  align-items: center;
  height: 80px;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.04);
  margin-top: 30px;
}

.input_item {
  border: none;
  display: block;
  height: 100%;
  font-size: 24px;
  background: transparent;
  color: #999999;
}

.money {
  font-size: 24px;
  color: #05C5FB;
  text-align: right;
  flex-grow: 1;
}

.btn_item {
  width: 100%;
  height: 80px;
  background : linear-gradient(to bottom, #02C3FD 0, #61FEAD 100%);
  color: #111111;
  line-height: 80px;
  text-align: center;
  font-size: 28px;
  border-radius: 40px;
  margin-top: 40px;
}
.input_content p{
  color: #999999;
   font-size: 22px;
}
</style>